import { css } from "@emotion/react";
import { Card, Button, Grid, Box } from "@mui/material";
import { useCustomMediaQuery } from "@unit/hooks";
import { appColor, appTypo, CustomMuiButton } from "@unit/styles";
import { SVGImgInitialTitle } from "@unit/svgs";
import React, { useState } from "react";

import PrimaryForm from "@/components/common/PrimaryForm";
import { useAppSnackbar } from "@/custom-hooks/use-app-snackbar";
import { useAuthentication } from "@/custom-hooks/use-authentication";
import { elevationApp } from "@/global-state/app-config";

const LoginContainer: React.FC = () => {
  const { isMobile } = useCustomMediaQuery();
  const { authLoginWithEmail } = useAuthentication();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { setAppSnackbar } = useAppSnackbar();

  const onClickEmailAuth = async () => {
    if (!email)
      setAppSnackbar("メールアドレスが未入力です", {
        error: true,
      });
    else if (!password)
      setAppSnackbar("パスワードが未入力です", {
        error: true,
      });
    if (email && password) {
      await authLoginWithEmail(email, password);
    }
  };

  return (
    <Grid px={2}>
      <Card elevation={elevationApp} css={containerStyled}>
        <Box py={3} px={isMobile ? 5 : 3}>
          <Grid container>
            <Grid item xs={12} justifyContent={"center"} textAlign={"center"} pb={2}>
              <SVGImgInitialTitle />
            </Grid>
            <Grid item xs={12} css={titleStyled} pb={3}>
              ユニット管理者ダッシュボード
            </Grid>
            <Grid item xs={12} pb={2}>
              <PrimaryForm
                type={"email"}
                required={true}
                label={"unit-admin@admin.jp"}
                title={"メールアドレス"}
                value={email}
                error={false}
                onChange={setEmail}
              />
            </Grid>
            <Grid item xs={12} pb={2}>
              <PrimaryForm
                type={"password"}
                required={true}
                title={"パスワード"}
                value={password}
                error={false}
                onChange={setPassword}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" pt={3} pb={2}>
            <Button
              css={CustomMuiButton}
              type="submit"
              fullWidth
              variant="contained"
              size={"large"}
              onClick={onClickEmailAuth}
            >
              ログイン
            </Button>
          </Grid>
        </Box>
      </Card>
    </Grid>
  );
};
export default LoginContainer;

const containerStyled = css`
  max-width: 472px;
  margin: auto;
  border-radius: 16px;
`;
const titleStyled = css`
  font-family: ${appTypo.font.notoJp};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${appColor.Text.primary};
  text-align: center;
`;
