import { APICompsSchema } from "@unit/apis";

export const SNACK_BAR_AUTO_HIDE_DURATION_M_SEC = 5000;
export const elevationApp = 3;
export const QUERY_MAX_LIMIT = 20000;
export const QUERY_INTERVAL_LIMIT = 2000;
export const INOPERABLE_INFLUENCER_STATUSES: APICompsSchema["InfluencerStatusEnum"][] = ["canceled"];

export const genderSelectModel: { type: string; value: string }[] = [
  {
    type: "male",
    value: "男性",
  },
  {
    type: "female",
    value: "女性",
  },
  {
    type: "other",
    value: "無回答",
  },
];

export const organizationTypeSelectModel: { type: APICompsSchema["OrganizationTypeEnum"]; value: string }[] = [
  {
    type: "company",
    value: "会社",
  },
  {
    type: "soleProprietor",
    value: "個人事業",
  },
];

export const StaffRoleEnumModel: { type: APICompsSchema["StaffRoleEnum"]; value: string }[] = [
  {
    type: "general",
    value: "一般スタッフ",
  },
  {
    type: "storeManager",
    value: "店長（ストアマネージャー）",
  },
  // {
  //   type: "areaManager",
  //   value: "エリアマネージャー",
  // },
  // {
  //   type: "brandManager",
  //   value: "ブランドマネージャー",
  // },
  {
    type: "supervisor",
    value: "全体責任者",
  },
];
